import React from "react";
// Customizable Area Start

import {Loader2} from "../../../../components/src/Loader.web";

import { HomeGrid, MainGRID } from "../CataloguePage.web";
import BlogPostManegementController, { AllSectionDataInterface, Props } from "./BlogPostManagementContoller.web";
import { NoResultIcon, Wallpaper, BlogPageImg } from "../assets";
import { ImaegStyle2 } from "../../../communityforum/src/CommunityForum.web";
import { Box, Grid, MenuItem, Select, TextField, Typography, styled } from "@material-ui/core";
import { SelectField } from "../../../email-account-registration/src/EmailAccountForm.web";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { Allstyle } from "../../../categoriessubcategories/src/Categories.web";

const baseUrl = require("../../../../framework/src/config.js")
// Customizable Area End

export default class BlogPostManagementMain extends BlogPostManegementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {

        return (
            //Merge Engine DefaultContainer
            // Customizable Area Start
            <>

                <Loader2 loading={this.state.isLoading} />
                <ImaegStyle2>
                    <ImageStyle src={BlogPageImg} style={{ width: "100%", height: "100%"}} />
                </ImaegStyle2>
                <Box style={BlogPostStyle.sectionBoxStyle}>
                    <MainGridSection container >
                        {this.state.allSectionData.map((item: AllSectionDataInterface,index:number) => {
                            const selectedCheck = this.state.selectedSection == item?.id ? true : false;
                            return <SubGridSection 
                            key={`selectSection${index}`}
                            lg={3} xs={12} md={3} sm={3} 
                            data-test-id={`selectSection${index}`}
                            style={{ ...BlogPostStyle.tabStyle, background: selectedCheck ? "#E8D658" : "#FFF", boxShadow: selectedCheck ? "0px 2px 8px 0px rgba(0, 0, 0, 0.08)" : "unset" }} onClick={() => {
                                this.setState({ selectedSection: item?.id,
                                    selectedSectionImg:item?.attributes?.banner,
                                     selectedSectionTitle: item?.attributes?.name,
                                     searchFieldBlog:"",
                                     sectionBymenu:"Select category" })
                            }}>
                                <Typography style={{ ...BlogPostStyle.tabTextStyle, fontWeight: selectedCheck ? 700 : 500, }}>
                                    {item?.attributes?.name}
                                </Typography>
                            </SubGridSection>
                        })
                        }


                    </MainGridSection>

                </Box>

                <HomeGrid >
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", marginBottom: "54px" }}>
                        <Typography style={{ ...BlogPostStyle.MainHeadingStyle, color: "#074F57" }}>
                            {this.state.selectedSectionTitle}{" "}
                            <span style={{ ...BlogPostStyle.MainHeadingStyle, color: "#FF5E5B" }}>
                                Blogs
                            </span>
                        </Typography>
                        <Box style={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            gap: "24px"
                        }}>
                            <AutocompleteComponent
                                freeSolo
                                //@ts-ignore
                                onChange={(event:any, newValue:string) => {
                                    this.setState({ searchFieldBlog: newValue });
                                }}
                                id="free-solo"
                                value={this.state.searchFieldBlog}
                            style={{ width: "275px" }}
                                data-test-id="free-solo2"
                                options={[]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={(e: any) => {
                                            this.setState({ searchFieldBlog: e.target.value })
                                        }}

                                        variant="outlined"
                                        value={this.state.searchFieldBlog}

                                        placeholder="Filter by keyword"
                                        margin="normal"
                                        InputProps={{
                                            ...params.InputProps, type: 'search', startAdornment: (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M15.1865 14.4713H15.9765L20.2165 18.7313C20.6265 19.1413 20.6265 19.8113 20.2165 20.2213C19.8065 20.6313 19.1365 20.6313 18.7265 20.2213L14.4765 15.9713V15.1813L14.2065 14.9013C12.8065 16.1013 10.8965 16.7213 8.86646 16.3813C6.08646 15.9113 3.86646 13.5913 3.52646 10.7913C3.00646 6.56133 6.56646 3.00133 10.7965 3.52133C13.5965 3.86133 15.9165 6.08133 16.3865 8.86133C16.7265 10.8913 16.1065 12.8013 14.9065 14.2013L15.1865 14.4713ZM5.47646 9.97133C5.47646 12.4613 7.48646 14.4713 9.97646 14.4713C12.4665 14.4713 14.4765 12.4613 14.4765 9.97133C14.4765 7.48133 12.4665 5.47133 9.97646 5.47133C7.48646 5.47133 5.47646 7.48133 5.47646 9.97133Z"
                                                        fill="#94A3B8"
                                                    />
                                                </svg>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <SelectField style={{ width: "274px", marginBottom: "unset" }} >
                                <Select
                                    variant="outlined"
                                    value={this.state.sectionBymenu}
                                    id="sectionBymenu"
                                    data-test-id={`sectionBymenu`}
                                    onChange={(e: any) => {
                                        this.setState({ sectionBymenu: e.target.value })
                                    }}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        MenuListProps: {
                                            style: {
                                                maxHeight: 398,
                                                padding: "10px",
                                            },
                                        },
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        fontFamily: "Lato",
                                        color: this.handleStyle()
                                    }}
                                    fullWidth
                                    IconComponent={KeyboardArrowDown}
                                >
                                    <MenuItem
                                        value="Select category"
                                        style={{
                                            display: "none",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            fontFamily: "Lato",
                                            color: "#94A3B8",
                                        }}

                                    >
                                        Select category
                                    </MenuItem>
                                    {this.state.allCatgoryData.map((item: {
                                        id: string,
                                        name: string,
                                    }) => {
                                        return <MenuItem
                                            value={item.id}
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                fontFamily: "Lato",

                                            }}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    })}
                                </Select>
                            </SelectField>
                        </Box>
                    </Box>
                   { this.state.allData.length>0 ?<MainGRID container style={{ marginBottom: "96px" }}>
                        {this.state.allData.map((item: any) => {

                            return (
                                <Grid
                                key={`section${item?.id}`}
                                    item
                                    data-test-id={`section${item?.id}`}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    style={{ ...Allstyle.subImgStyle, marginBottom: "unset", cursor: "pointer" }}
                                onClick={()=>{
                                  this.props.navigation.navigate("BlogPostManagementDetails",{id:item?.id,selectionId:this.state.selectedSection})
                                }}
                                >
                                    <img
                                        src={`${item?.attributes?.image}`}
                                        style={{
                                            width: "274px",
                                            height: "230px",
                                            objectFit: "cover",
                                            borderTopRightRadius: "8px",
                                            borderTopLeftRadius: "8px",
                                        }}
                                    />
                                    <Box style={{ paddingInline: "16px", paddingTop: "12px" }}>

                                        <Typography style={BlogPostStyle.nameTitleStyle}>
                                            { item.attributes.title.length>=50?

item.attributes.title.slice(0,50)+'...'
                                           : item.attributes.title}
                                        </Typography>
                                        <Box
                                            style={{ paddingTop: "12px", paddingBottom: "16px", }}
                                        >
                                            <Typography
                                                style={BlogPostStyle.dateTitleStyle}
                                            >
                                                <span style={{
                                                       whiteSpace: "nowrap",
                                                       overflow: "hidden",
                                                       width:"113px",
                                                       textOverflow: "ellipsis"
                                                }}>

                                                    {item?.attributes?.blog_post_category?.name}
                                                </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="20" viewBox="0 0 2 20" fill="none">
                                                    <path d="M1 1L0.999999 19" stroke="#64748B" stroke-linecap="round" />
                                                </svg>
                                                <span >
                                                    {moment(item?.attributes?.updated_at).format("MMM DD, YYYY")}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </MainGRID>
                    :
                  
                    <Box style={{ marginBottom: "100px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>

                    <img src={this.state.blogNoDataFoundImage || NoResultIcon} alt="Empty" style={{marginBottom:"32px",width:"288px"}}/>
                    <Typography style={{
                        color: "#000",
                        fontFamily: "Lato",
                        fontSize: "24px",
                        fontWeight: 700,
                        lineHeight: "32px",
                        letterSpacing: "-0.12px",
                        marginBottom:"8px"
                    }}>
                        {'No results found!'}
                    </Typography>
                    <Box style={{ maxWidth: "373px",marginBottom:"20px",display:"flex",alignItems:"center",justifyContent:"center",textAlign:"center" }}>
                        <Typography
                            style={{
                                color: "#475569",
                                fontFamily: "Lato",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "150%",
                            }}>
                         No matching results for your search. Please try again with different keywords.
                        </Typography>
                    </Box>
              </Box>
                    }
                </HomeGrid>

            </>
            // Customizable Area End
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
const MainGridSection = styled(Grid)({
    justifyContent: "center", maxWidth: "1170px", borderRadius: "50px", padding: "4px", background: "#fff",
    '@media(max-width: 1200px)': {
        marginInline: "4px"
    },
    '@media(max-width: 600px)': {
        background: "unset",
        gap: "4px",
        marginInline: "4px"

    },

})
const ImageStyle=styled('img')({
    objectFit:"contain",

    '@media(max-width: 1440px)': {
        objectFit:"cover"
    }
})
const SubGridSection = styled(Grid)({
    height: "64px",
    '@media(max-width: 600px)': {
        height: "44px",
    }
})
const AutocompleteComponent = styled(Autocomplete)({

  
    "& .MuiOutlinedInput-root": {
        height: "44px",

        "&:hover fieldset": {
            borderColor: "#CBD5E1",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#CBD5E1",
        },
    },
    "& .MuiFormControl-marginNormal": {
        marginTop: "unset",
        marginBottom: "unset",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: "0px 0px 0px 8px"
    },
   
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: "10px 8px"
    },
    "& .MuiInputBase-root": {
        color: "#334155"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderRadius: "8px"
    },
    "& .MuiFormControl-root": {
        display: "inline",
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px"
    },
   

})
const BlogPostStyle = {
    MainHeadingStyle: {

        fontFamily: "Cardenio Modern",
        fontSize: "26px",
        fontWeight: 700,
        lineHeight: "44px",
        letterSpacing: "0.52px",
    },
    sectionBoxStyle: {
        background: "#074F57",
        width: "100%",
        display: 'flex',
        alignItems: "center",
        minHeight: "104px",
        justifyContent: "center"
    },
    tabStyle: {
        display: 'flex',
        borderRadius: "50px",
        justifyContent: "center",
        cursor: "pointer",
        alignItems: 'center',
    },
    tabTextStyle: {
        color: "var(--Primary-color-2, #074F57)",
        fontFamily: "Inter",
        fontSize: "16px",
        lineHeight: "24px",
    },
    optionBtnStyle: {
        background: "#FFF",
        border: "1px solid #CBD5E1",
        padding: "4px 8px 4px 16px",
        height: "44px",
        borderRadius: "8px",
        textTransform: "inherit" as "inherit",
        color: "#334155",
        fontFamily: "Lato" as "Lato",
        fontSize: "16px",
        fontWeight: 400,
    },
    nameTitleStyle: {
        color: " var(--Neutrals-Cool-gray-900, #0F172A)",
        fontFamily: "Lato",
        fontSize: "18px",
        height:"52px",
        fontWeight: 700,
        overflow: "hidden",
        lineHeight: "26px",
    },
    dateTitleStyle: {
        color: "var(--Neutrals-Cool-gray-500, #64748B)",
        fontFamily: "Inter" as "Inter",
        display: "flex" as "flex",
        gap: "16px",
        wordBreak: "break-all" as "break-all",
        alignItems: "center" as "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px"
    }
};
// Customizable Area End

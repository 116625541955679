import React from "react";

// Customizable Area Start
import { lazy } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  styled,
  FormControl,
  DialogTitle,
  DialogContent,
  Snackbar,
  Tooltip,
  Input,
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { MainGrid, SecondGrid, MainBox } from "./UserRole.web";
import { PetImage, checkBoxFalse, checkBoxTrue ,infoIcon, calender } from "./assets";

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EmailOtpVerifyController, { Props } from "./EmailOtpLoginController.web";
import "react-phone-number-input/style.css";
import PhoneInput from 'react-phone-number-input'
import { KeyboardArrowDown, VisibilityOff } from "@material-ui/icons";
import Visibility from "@material-ui/icons/Visibility";
const configJSON = require("./config");
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { InputField } from "./EmailAccountEmailVerification.web";
import Calendar from 'react-calendar';
import { enUS } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: "#FFF",
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    padding: "8px 16px",
    lineHeight: "22px",
    borderRadius: "8px",
    border: "0.5px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
    boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)",

  },
})(Tooltip);
// Customizable Area End

class EmailAccountForm extends EmailOtpVerifyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPhoneInputRescuer = () => {
    return (
      this.state.userType == "rescuer" ? <PhoneStyle
        data-test-id="txtInputPhonenumber"
        borderColor={this.handleColorPhone()}
        className="custominput"
        value={this.state.fomatMObileNumber}
        onChange={(e: any) => {

          this.handlePhoneNumber(e)
        }}
        defaultCountry="AE"
        countries={['AE']}
        international
        countryCallingCodeEditable={false}
      /> : null
    )
  }
  renderPhoneInputAdopter = () => {
    return (
      this.state.userType == 'adopter' && <PhoneStyle
        data-test-id="txtInputPhonenumber2"
        borderColor={this.handleColorPhone()}
        className="custominput"
        value={this.state.fomatMObileNumber}
        onChange={(e: any) => {

          this.handlePhoneNumber(e)
        }}
        defaultCountry="AE"
        countries={[]}
        international

      />
    )

  }
  renderPhoneErr = () => {
    return (
      this.state.phoneError && (
        <Typography
          style={{ ...AllStyle.errorTextStyle, marginBottom: "16px" }}
        >
          {"Please enter a valid phone number"}
        </Typography>
      )
    )
  }
  renderCountryResidenceAdopterTitle = () => {
    return (this.state.userType == "adopter" && <Typography style={AllStyle.textStyle}>
      Country of Residence  {this.importantField()}
    </Typography>)
  }
  renderSelectCountryResidenceAdopter = () => {
    return (
      this.state.userType == "adopter" && <SelectField fullWidth >
        <Select
          fullWidth
          style={{
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Lato",
            color: this.handleColorStyle("residence")
          }}
          IconComponent={KeyboardArrowDown}
          error={this.state.residenceError}
          variant="outlined"
          onChange={(e: any) => {
            this.handleSeprateValidationSelect("residence", e.target.value, "residenceError");

          }}
          value={this.state.residence}
          data-test-id="Residence"
          MenuProps={{
            MenuListProps: {
              style: {
                padding: "10px",
                maxHeight: 398,
              },
            },
            anchorOrigin: {
              horizontal: "left",
              vertical: "bottom",
            },
            getContentAnchorEl: null,

          }}
        >
          <MenuItem
            value="Select"
            style={{
              display: "none",
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: 400,
              color: "#94A3B8",
            }}
          >
            Select
          </MenuItem>
          {this.hanldeDropDown(this.state.countriesData)}
        </Select>
        {this.handleError(this.state.residenceError)}
      </SelectField>
    )
  }
  renderEmirateTitle = () => {
    return (
      this.state.residence == '1' || this.state.userType == 'rescuer' ? <Typography style={AllStyle.textStyle}>
        Emirate  {this.importantField()}
      </Typography> : ""
    )
  }
  renderEmirateSelect = () => {
    return (
      this.state.residence == '1' || this.state.userType == 'rescuer' ? <SelectField fullWidth  >
        <Select
          error={this.state.emirateError}
          variant="outlined"
          value={this.state.emirate}
          data-test-id="Emirate"
          onChange={(e: any) => {
            this.handleSeprateValidationSelect("emirate", e.target.value, "emirateError");
          }}
          MenuProps={{
            getContentAnchorEl: null,
            MenuListProps: {
              style: {
                maxHeight: 398,
                padding: "10px",
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          style={{
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Lato",
            color: this.handleColorStyle("emirate")
          }}
          fullWidth
          IconComponent={KeyboardArrowDown}
        >
          <MenuItemStyle
            value="Select"
          >
            Select
          </MenuItemStyle>
          {this.hanldeDropDown(this.state.emirateData)}
        </Select>
        {this.handleError(this.state.emirateError)}
      </SelectField> : ""
    )
  }
  renderCityAreaTitle = () => {
    return (
      <Typography style={AllStyle.textStyle}>
        {this.state.userType == 'rescuer' || this.state.residence == '1' ? 'Area' : 'City'}   {this.importantField()}
      </Typography>
    )
  }
  renderCityAreaSelect = () => {
    return (
      this.state.residence == '1' || this.state.userType == 'rescuer' ? <InputField
        onChange={(e: any) => {
          this.handleSeprateValidationSelect("area", e.target.value, "areaError");
        }}
        helperText={this.state.areaError && "Please enter a value"}
        value={this.state.area}
        error={this.state.areaError}
        data-test-id="Area"
        placeholder="Area"
        id="areaName"
        fullWidth={true}
        variant="outlined"
        inputProps={{
          maxLength: 50,
        }}
      /> : <InputField
        onChange={(e: any) => {
          this.handleSeprateValidationSelect("city", e.target.value, "cityError");
        }}
        helperText={this.state.cityError && "Please enter a value"}
        value={this.state.city}
        error={this.state.cityError}
        data-test-id="city"
        placeholder="City"
        id="cityName"
        fullWidth={true}
        variant="outlined"
        inputProps={{
          maxLength: 50,
        }}
      />
    )
  }
  renderDob = () => {
    return (

      <DateBox style={{ position: "relative" }}>
        <Box style={{ position: "relative" }}>

          <DateStyle
            placeholder="DD/MM/YYYY"
            disabledStyle={this.state.dateofbirthError}
            value={this.convertFormat()}
            fullWidth
            disableUnderline
            name="companyName"
            data-test-id="DateOfBirth_Age"
          />

          <img
            src={calender}
            id="user-date"
            data-test-id='user-date'
            style={{cursor:'pointer', marginRight: "10px", position: 'absolute', top: 9, right: 0, width: "24px", height: "24px", }}
            onClick={() => this.openDatePicker()}
          />
          {this.state.datePickerIsOpen && (
            <div style={{ position: 'absolute', top: 60, zIndex: 1000 }}>
              <StyleDateBox
                className="calenderBox"
                value={this.state.dateofbirth}
                onClickDay={this.handleChanges}
                data-test-id="calenderTestID"
                maxDate={new Date()}
                />
            </div>
          )}

        </Box>
        {this.state.dateofbirthError && (
          <Typography
            style={AllStyle.errorTextStyle}
          >
            {this.state.dateOfBirthMessage}
          </Typography>)
        }
      </DateBox>
    )
  }
  renderPasswordInput = () => {
    return (<Box style={{ marginBottom: this.state.userType === "adopter" ? "16px" : "30px" }}>
      <InputField
        error={this.state.passwordError}
        placeholder="Password"
        data-test-id="txtInputPassword"
        type={this.handleVisiblPAssword()}
        fullWidth={true}
        value={this.state.password}
        variant="outlined"
        style={{ marginBottom: "unset" }}
        onChange={(e: any) => {
          this.handlePassword(e.target.value)

        }}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => { this.setState({ enablePasswordField: !this.state.enablePasswordField }) }}
              edge="end"
            >
              {this.state.enablePasswordField ? (
                <VisibilityOff />
              ) : (
                <Visibility />
              )}
            </IconButton>
          ),
        }}
      />
      {this.state.passwordError && (
        <Typography
          style={AllStyle.errorTextStyle}
        >
          {"Please enter a valid message of minimum 5 characters"}
        </Typography>)
      }
    </Box>)
  }
  renderConfirmPasswordInput = () => {
    return (<Box style={{ marginBottom: this.state.userType === "adopter" ? "16px" : "30px" }}>
      <InputField
        error={this.state.confirmPasswordError}
        placeholder="Confirm Password"
        data-test-id="txtInputConfirmPassword"
        type={this.handleVisiblConfirmPAssword()}
        fullWidth={true}
        value={this.state.confirmPassword}
        variant="outlined"
        style={{ marginBottom: "unset" }}
        onChange={(e: any) => {
          this.handleConfirmPassword(e.target.value)
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => { this.setState({ enableConfirmPasswordField: !this.state.enableConfirmPasswordField }) }}
              edge="end"
              data-test-id='PasswordEyeIcon'
            >
              {this.state.enableConfirmPasswordField ? (
                <VisibilityOff />
              ) : (
                <Visibility />
              )}
            </IconButton>
          ),
        }}
      />
      {this.state.confirmPasswordError && (
        <Typography
          style={AllStyle.errorTextStyle}
        >
          {this.state.passwordNotMatchMessage}
        </Typography>)
      }
    </Box>)
  }
  renderPreferencesText = () => {
    return (this.state.userType == 'adopter' ? 'Pets Preferences' : 'Which animal categories do you primarily rescue?') + ' '
  }
  rescueStyle = () => { return this.state.rescueCheck ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "unset" }
  rescueStyleText = () => { return this.state.rescueCheck ? "#64748B" : "#0A2239" }
  handleForm = () => {
    const { veterinaryEntries } = this.state;

    // Include the fields for the initial newEntry
    const allEntries = [...veterinaryEntries];
    return (
      <>
        <Typography id="test" style={{ color: "#0F172A", marginBottom: "16px", display: "flex", alignItems: "center", fontWeight: 400, fontSize: "16px", fontFamily: 'Lato', cursor: "pointer", lineHeight: "24px" }} onClick={() => {

          const check = !this.state.rescueCheck
          this.setState({ rescueCheck: !this.state.rescueCheck ,rescueGroup:""})
          if (check) {
            this.setState({ rescueGroupError: false, groupContactError: false, groupNameError: false })
          }
        }}>
          <img src={this.state.rescueCheck ? checkBoxTrue : checkBoxFalse} style={{ marginRight: "3px", width: "20px", height: "20px" }} />
          I am not associated with any rescue group.
        </Typography>

        <Typography style={{ ...AllStyle.textStyle, fontWeight: 700, color: this.rescueStyleText(), textShadow: this.rescueStyle() }}>
          Rescue Group Name  {!this.state.rescueCheck && this.importantField()}
        </Typography>
        <InputField
          onChange={(e: any) => {
            this.hanldeRescueForm("rescueGroup", e.target.value, "rescueGroupError",100)

          }}
          disabled={this.state.rescueCheck}
          textColor={this.state.rescueCheck?"#64748B":"#334155"}
          error={this.state.rescueGroupError}
          helperText={this.state.rescueGroupError && !this.state.rescueCheck && "Please enter a value"}
          value={this.state.rescueGroup}
          placeholder="Rescue Group Name"
          id="rescueGroup"
          fullWidth={true}
          variant="outlined"
        />
        <Typography data-test-id='toolTipText' style={{ ...AllStyle.textStyle, fontSize: "16px", fontFamily: "Lato", marginTop: "14px", display: "flex", alignItems: "center" }}>
          <span>Veterinary Clinics {this.importantField()} </span>

          <LightTooltip
              id="tooltip11"
           open={this.state.tooltipOpenchannelHeadingTooltip}
           onClose={()=>this.handleTooltipCloseChanelsData()}
            title="Please provide the general information for at least one veterinary clinic that you use. Such clinic must agree to have their contact information provided as they maybe contacted to confirm your identity."
            placement="right"
          >
            <IconButtonStyleShow id='infoIcon'      
            onClick={() => this.handleHeading()}      
               onMouseLeave={()=>this.handleHeadingMouseOver()}
                            onMouseEnter={()=>this.handleHeadingMouseHover()}  >
              <img src={infoIcon} />
            </IconButtonStyleShow>
          </LightTooltip>
        </Typography>
        {allEntries.map((entry, index) => (
          <VeterinaryEntryComponent
            index={index}
            errors={this.state.errors}
            key={index}
            entry={entry}
            onChange={(field: string, value: any) =>
              this.handleEntryInputChange(index, field, value)
            }
            data-test-id='allEntries'
            importantField={this.importantField}
            handleRemove={this.handleRemove}
            state={this.state.veterinaryEntries}
          />
        ))}
        {this.state.veterinaryEntries.length < 3 && <Typography
          style={{
            cursor: "pointer",
            display: "flex",
            marginBottom: "30px",
            fontSize: "14px",
            fontWeight: 700,
            fontFamily: "Lato",
            color: "00bdab",
            alignItems: "center",
            width: "max-content"
          }}
          id="addone"
          onClick={this.handleAddAnother}
          data-test-id='addAnother'
        >
          <AddCircleOutlineIcon
            style={{ color: "00bdab", marginRight: "4px" }}
          />{" "}
          Add another
        </Typography>}
      </>
    );
  };

  hanldeDropDown = (data: any) => {
    return data?.data?.length > 0 && data?.data.map((option: any) => (
      <MenuItem
        key={option?.id}
        value={option?.id}
      >{`${option?.name}`}</MenuItem>
    ))
  }
  importantField = () => {
    return <span style={{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FF5E5B"
    }}>
      *
    </span>
  }
  linkForm = () => {
    return <>
      <Typography style={{ ...AllStyle.textStyle }}>
        Facebook Account Link
      </Typography>
      <InputField
        id="facebook"
        error={this.state.facebookLinkError}
        helperText={
          this.state.facebookLinkError && "Please provide valid link"
        }
        onChange={(e: any) => {
          this.handleLink("facebookLink", e.target.value, "facebookLinkError")
        }}
        value={this.state.facebookLink}

        placeholder="Facebook Account Link"
        data-test-id="txtInputPassword"
        fullWidth={true}
        variant="outlined"
      />
      <Typography style={AllStyle.textStyle}>
        TikTok Account Link
      </Typography>
      <InputField
        error={this.state.tiktokLinkError}
        helperText={
          this.state.tiktokLinkError && "Please provide valid link"
        }
        onChange={(e: any) => {
          this.handleLink("tiktokLink", e.target.value, "tiktokLinkError")

        }}
        id="tiktok"

        value={this.state.tiktokLink}

        placeholder="TikTok Account Link"
        data-test-id="txtInputPassword"
        fullWidth={true}
        variant="outlined"
      />
      <Typography style={AllStyle.textStyle}>
        Linkedln Account Link
      </Typography>
      <InputField
        id="linkdin"

        error={this.state.linkdinLinkError}
        helperText={
          this.state.linkdinLinkError && "Please provide valid link"
        }
        onChange={(e: any) => {
          this.handleLink("linkdinLink", e.target.value, "linkdinLinkError")

        }}
        value={this.state.linkdinLink}

        placeholder="Linkedln Account Link"
        data-test-id="txtInputPassword"
        fullWidth={true}
        variant="outlined"
      />
      <Typography style={AllStyle.textStyle}>
        Snapchat Account Link
      </Typography>
      <InputField
        error={this.state.snapchatLinkError}
        helperText={
          this.state.snapchatLinkError && "Please provide valid link"
        }
        onChange={(e: any) => {
          this.handleLink("snapchatLink", e.target.value, "snapchatLinkError")

        }}
        id="snapchat"

        value={this.state.snapchatLink}

        placeholder="Snapchat Account Link"
        data-test-id="txtInputPassword"
        fullWidth={true}
        variant="outlined"
      />
      <Typography style={AllStyle.textStyle}>
        Instagram Account Link
      </Typography>
      <InputField
        error={this.state.instagramLinkError}
        helperText={
          this.state.instagramLinkError && "Please provide valid link"
        }
        onChange={(e: any) => {
          this.handleLink("instagramLink", e.target.value, "instagramLinkError")
        }}
        id="instagram"
        value={this.state.instagramLink}
        style={{ marginBottom: "30px" }}
        placeholder="Instagram Account Link"
        data-test-id="txtInputPassword"
        fullWidth={true}
        variant="outlined"
      />
    </>
  }
  handleButtonName = () => { return this.state.userType === "adopter" ? "Login" : "Okay" }
  navigate = (ButtonName: string) => {
    if (ButtonName === "Login") {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
    else {
      this.setState({ success: false })
    }
  }
  handleError = (error: boolean) => {
    return error && (
      <Typography
        style={AllStyle.errorTextStyle}
      >
        Please select an option
      </Typography>)
  }
  handleStyle = () => {
    return this.state.phoneError ? "4px" : "16px"
  }
  hanldePlaceholder = () => {
    return this.state.fomatMObileNumber == "" && "00000 00000"
  }
  handleImageStyle=()=>{
   
    if(this.state.signUpFormImage!==""){
      return {
        minHeight: 'calc(100vh - 247px)',
        maxHeight: 'calc(100vh - 247px)',
        width:"100%",
        borderRadius:"0px 0px 0px 45px",
        objectFit: 'cover' as 'cover',
        objectPosition: 'top' as 'top'
      }
    }
    else{
      return { width:"335px" }
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start

    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.open}
          autoHideDuration={5000}
          onClose={this.closeSnackbar2}
        >
          <Typography style={AllStyle.popup as React.CSSProperties}>
            <InfoIcon style={{ marginRight: "8px", color: "#F59E0B" }} /> {this.state.info}
          </Typography>
        </Snackbar>
        
        <MainGrid container>
          <SecondGrid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <MainBox
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography style={AllStyle.heading}>Hi, {this.state.firstName}</Typography>
              <SecondBox style={AllStyle.secondBox}>

                <Box
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography style={AllStyle.textStyle}>
                    Phone Number {this.importantField()}
                  </Typography>

                  <div style={{ position: 'relative', marginBottom: this.handleStyle() }}>
                    <label style={{
                      position: 'absolute', top: 10, left: 100, fontFamily: "Lato",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "24px", color: '#94A3B8'
                    }} htmlFor="">{this.hanldePlaceholder()}</label>

                    {this.renderPhoneInputRescuer()}
                    {this.renderPhoneInputAdopter()}
                  </div>
                  {this.renderPhoneErr()}
                  {this.renderCountryResidenceAdopterTitle()}

                  {this.renderSelectCountryResidenceAdopter()}

                  {this.renderEmirateTitle()}
                  {this.renderEmirateSelect()}
                  {this.renderCityAreaTitle()}

                  {this.renderCityAreaSelect()}

                  <Typography style={AllStyle.textStyle}>
                    Gender  {this.importantField()}
                  </Typography>

                  <SelectField fullWidth >
                    <Select
                      variant="outlined"
                      onChange={(e: any) => {
                        this.handleSeprateValidationSelect("gender", e.target.value, "genderError");


                      }}
                      value={this.state.gender}
                      data-test-id="Gender"
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            maxHeight: 398,
                            padding: "10px",
                          },
                        },
                        getContentAnchorEl: null,

                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                      fullWidth
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: this.handleColorStyle("gender")


                      }}
                      error={this.state.genderError}

                      IconComponent={KeyboardArrowDown}
                    >
                      <MenuItem
                        value="Select"
                        style={{
                          display: "none",
                          fontSize: "16px",
                          fontWeight: 400,
                          fontFamily: "Lato",
                          color: "#94A3B8",
                        }}
                      >
                        Select
                      </MenuItem>
                      {configJSON.gender.map((option: string) => (
                        <MenuItem
                          key={option}
                          value={option}
                        >{`${option}`}</MenuItem>
                      ))}
                    </Select>
                    {this.handleError(this.state.genderError)}


                  </SelectField>
                  <Typography style={AllStyle.textStyle}>
                    Date of Birth  {this.importantField()}
                  </Typography>
                  {this.renderDob()}
                  <Typography style={AllStyle.textStyle}>
                    Nationality
                  </Typography>
                  <SelectField fullWidth >
                    <Select
                      variant="outlined"
                      value={this.state.nationality}
                      onChange={(e: any) => {
                        this.setState({ nationality: e.target.value });
                      }}
                      data-test-id="Nationality"
                      MenuProps={{
                        getContentAnchorEl: null,
                        MenuListProps: {
                          style: {
                            maxHeight: 398,
                            padding: "10px",
                          },
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                      fullWidth
                      style={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontWeight: 400,
                        color: this.handleColorStyle("nationality")
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      <MenuItem
                        value="Select"
                        style={{
                          display: "none",
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#94A3B8",
                          fontFamily: "Lato",
                        }}
                      >
                        Select
                      </MenuItem>
                      {this.hanldeDropDown(this.state.nationalitiesData)}
                    </Select>
                  </SelectField>

                  <Typography style={AllStyle.textStyle}>
                    Password  {this.importantField()}
                  </Typography>
                  {this.renderPasswordInput()}
                  <Typography style={AllStyle.textStyle}>
                    Confirm Password  {this.importantField()}
                  </Typography>
                  {this.renderConfirmPasswordInput()}

                  {this.state.userType === "rescuer" && this.handleForm()}
                  <Box style={{ display: "flex", flexDirection: "column", marginBottom: "30px" }}>
                    <TooliTipBoxStyle >

                      <Typography style={{ ...AllStyle.textStyle, display: "flex", alignItems: "center",marginBottom:"0px" }}>
                        <span> <span >{this.renderPreferencesText()} </span> {this.importantField()} </span>

                      </Typography>
                        <LightTooltip
                        data-test-id="tooltip22"
                          open={this.state.toolTip2}
                          onClose={()=>this.handleTooltipCloseChanelsData2()}
                          title={this.state.userType == 'adopter' ? "Please check which animals you would love to adopt or foster" : "Please check which animals you currently rescue and would like to list"}
                          placement="right" >
                          <IconButtonStyleShow 

                           onClick={() => this.handleHeading2()}      
                           onMouseLeave={()=>this.handleHeadingMouseOver2()}
                                        onMouseEnter={()=>this.handleHeadingMouseHover2()}  
                         
                          data-test-id='infoComp'  >
                            <img src={infoIcon} />
                          </IconButtonStyleShow>
                        </LightTooltip>
                      {this.state.petsError && (
                        <Typography
                          style={AllStyle.errorTextStyle}
                        >
                          Please select at least one option
                        </Typography>
                      )}
                    </TooliTipBoxStyle>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box style={{ display: "flex", flexDirection: "column" }}>

                        {["Cats", "Dogs", "Reptiles"].map((item: string, id: number) => {
                          return <Typography key={id}
                            style={AllStyle.petReference}
                            data-test-id="test"
                            onClick={() => {
                              this.handlePetSelection(item)

                            }}>
                            <img src={this.state.pets.includes(item) ? checkBoxTrue : checkBoxFalse} style={AllStyle.checkBoxStyle} />
                            {item}
                          </Typography>
                        })}

                      </Box>
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        {["Birds", "Rabbits", "Little Critters"].map((item: string, id: number) => {
                          return <Typography
                            data-test-id="test2"
                            key={id}
                            style={AllStyle.petReference}
                            onClick={() => {
                              this.handlePetSelection(item)

                            }}>
                            <img src={this.state.pets.includes(item) ? checkBoxTrue : checkBoxFalse} style={AllStyle.checkBoxStyle} />
                            {item}
                          </Typography>
                        })}

                      </Box>
                    </Box>
                  </Box>
                  {this.state.userType === "rescuer" && this.linkForm()}
                </Box>
                <BUttonStyle
                  style={{ ...AllStyle.btnStyle }}
                  data-test-id="buttotnclick"
                  variant="contained"
                  onClick={() => {
                    this.handleValidation();
                  }}
                >
                  Sign up
                </BUttonStyle>
                <Typography style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19.2px",
                  color: "#0A2239"
                }}>
                  Already have an account?{" "}
                  <span style={{ ...AllStyle.boldStyle, cursor: "pointer" }} data-test-id="navigate" onClick={() => { this.handleNavigateLogin() }}>
                    Log in
                  </span>
                </Typography>
              </SecondBox>
            </MainBox>
          </SecondGrid>

        </MainGrid>
        {this.state.success && <DiologComponent success={this.state.success} handleClose={this.handleClose} firstHeading={this.state.userType == "adopter" ?"CONGRATULATIONS!":'FUR-TASTIC!'} secondHeading={this.state.userType == "adopter" ? "You've just joined the cool club! Your account is now live and ready for action. Click below and let your adventure begin." : "We've got your details. Sit tight, we will be reaching out shortly. Stay tuned!"} ButtonName={this.handleButtonName()} navigate={this.props.navigation.navigate} />}
        
      </Box>
      // Customizable Area End
    );
  }
}
export default EmailAccountForm;

// Customizable Area Start
export const VeterinaryEntryComponent = ({ entry, onChange, errors, index, importantField, handleRemove, state }: any) => {
  let style = () => {
    return index == 2 ? "30px" : "16px";
  }
  return (
    <div>
      <Box style={{ marginBottom: "16px", display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <Typography style={
          {
            color: "#64748B",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "22px"
          }}>
          Clinic {index + 1}
        </Typography>
        {state?.length > 1 && <Typography onClick={() => { handleRemove(index) }} style={{

          color: "#FF5E5B",
          fontFamily: "Lato",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "22px",
          cursor: "pointer"
        }}>
          Remove
        </Typography>}
      </Box>
      <Typography style={AllStyle.textStyle}>
        Veterinarian Name  {importantField()}
      </Typography>
      <InputField
        error={errors[index]?.veterinaryNameError}
        helperText={errors[index]?.veterinaryNameError && "Please enter a value"}
        placeholder="Veterinarian Name"
        id="veterinaryNameError"
        fullWidth={true}
        variant="outlined"
        value={entry.veterinaryName}
        onChange={(e: any) => onChange("veterinaryName", e.target.value)}
      />
      <Typography style={AllStyle.textStyle}>
        Clinics Name  {importantField()}
      </Typography>
      <InputField
        error={errors[index]?.clinicsNameError}
        helperText={errors[index]?.clinicsNameError && "Please enter a value"}
        placeholder="Clinics Name"
        id="clinicsNameError"

        data-test-id="txtInputPassword"
        fullWidth={true}
        variant="outlined"
        value={entry.clinicsName}
        onChange={(e: any) => onChange("clinicsName", e.target.value)}
      />
      <Typography style={AllStyle.textStyle}>
        Contact Number  {importantField()}
      </Typography>
      <div style={{ position: 'relative', marginBottom: errors[index]?.contactNumberError ? "4px" : style() }}>
        <label style={{
          position: 'absolute', top: 10, left: 100, fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px", color: '#94A3B8'
        }} htmlFor="">{entry.contactNumber == "" && "00000 00000"}</label>

        <PhoneStyle
          id="contactNumberError"

          value={entry.contactNumber}
          onChange={(e: any) => {
            onChange("contactNumber", e)
            onChange("formattedcontactNumber", e)
          }}
          defaultCountry="AE"
          countries={[]}
          international
          countryCallingCodeEditable={false}
          borderColor={errors[index]?.contactNumberError ? "#F87171" : "#CBD5E1"}

        />

      </div>
      {
        errors[index]?.contactNumberError && (
          <Typography
            style={{ ...AllStyle.errorTextStyle, marginBottom: "16px" }}
          >
            Please enter a valid phone number
          </Typography>
        )
      }
    </div >
  );
};

export const DiologComponent = ({ success, handleClose, firstHeading, secondHeading, ButtonName, navigate }: any) => {
  const navigateTo = (ButtonName: string) => {
    if(firstHeading=='PASSWORD UPGRADE IS COMPLETE!'){
      handleClose()
    }
    else if(firstHeading=='COMMENT POSTED!'){
      handleClose()
    }
   else if (ButtonName === "Login") {
      navigate("EmailAccountLoginBlock");
    }
    else {
      navigate("LandingPageWeb");
    }
  }
  return <DiologStyle  onClose={handleClose} aria-labelledby="customized-dialog-title" open={success}>
    <DialogTitle id="customized-dialog-title"
      //@ts-ignore
      onClose={handleClose} style={{ textAlign: "end", padding: "16px 24px 0px 24px " }}>
      <IconButton aria-label="close" onClick={handleClose} style={{ color: "#0A2239" }}>
        <CloseIcon />
      </IconButton>

    </DialogTitle>
    <DialogContent style={{ textAlign: "center", paddingInline: "40px", paddingTop: "0px " }}>
      <Typography style={{
        fontSize: "24px", fontWeight: 700, marginBottom: "12px",
        fontFamily: "Lato",
      }}>
        {firstHeading}
      </Typography>
      <Typography style={{ fontSize: "18px", fontWeight: 400, fontFamily: "Lato", marginBottom: "20px", }}>
        {secondHeading}
      </Typography>
    </DialogContent>
    <DialogContent dividers style={{ textAlign: "end" }}>
      <Button data-test-id='dialogButton' variant="contained" style={{
        ...AllStyle.btnStylePopUp,

      }} onClick={() => { navigateTo(ButtonName) }}>
        {ButtonName}
      </Button>
    </DialogContent>

  </DiologStyle>
}
const IconButtonStyleShow = styled(IconButton)({

  "&.MuiIconButton-root:hover": {
    background: "unset"
  },
  '@media (max-width:600px )': {
    display: "block"
  },
})
const StyleDateBox = styled(Calendar)({
  "& .rdrDayNumber span": {
    fontWeight: 700
  },
  "& .rdrMonth": {
    width: "unset"
  }
})
const DateStyle = styled(Input)(({ disabledStyle }: any) => ({
  minWidth: "175px",
  border: disabledStyle ? "1px solid #F87171" : "1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
  borderRadius: "8px",
  height: "44px",
  padding: "10px 8px",
  '& input::placeholder': {
    color: '#94A3B8',
    opacity:1
  },

}));
const DateBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "384px",
  minWidth: "384px",
  marginBottom: "16px",
  '@media (max-width:660px )': {
    minWidth: "unset",
    maxWidth: "unset",
    flexWrap: "wrap",
    flexDirection: "column"
  },
})
const DiologStyle = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "600px",
    minWidth: "600px",
    borderRadius: "8px 8px 32px 8px"
  },
  "@media (max-width:722px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "unset",
      borderRadius: "8px 8px 32px 8px"
    },
  },
})


const MenuItemStyle = styled(MenuItem)({
  display: "none",
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 400,
  color: "#94A3B8 !important",
})

const BUttonStyle = styled(Button)({
  minWidth: "360px",

  "@media (max-width:380px)": {
    minWidth: "200px",
  },

})

export const PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => ({
  border: `1px solid ${borderColor || "#F87171"}`,
  borderRadius: 8,
  height: 41,
  zIndex: 1,
  position: "relative",
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 10,
  '& input': {
    border: 'none'
  },
  '& input:focus': {
    border: 'none',
    outline: 'none'
  },
  '& .PhoneInputInput': {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    background: "transparent",

    lineHeight: "24px"
  },
  '& input:focus-visible': {
    border: 'none',
    outline: 'none'
  }
}));

const TooliTipBoxStyle=styled(Box)({
  display: "flex", flexDirection: "row",
  flexWrap:"wrap",
   justifyContent: "space-between", marginBottom: "16px", alignItems: 'center',
   "@media (max-width:660px)": {
 flexDirection:"row",
//  alignItems:"flex-start"
  },

})

export const SelectField = styled(FormControl)({
  marginBottom: "16px",

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#CBD5E1",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px 8px",
  },
  "& .MuiInputBase-input": {
    lineHeight: "19.2px",

  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px"
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  }
});


const SecondBox = styled(Box)({
  maxWidth: "360px",
  "@media (max-width:390px)": {
    paddingInline: "10px"
  },
  "@media (max-width:320px)": {
    justifyContent: "center",
  },
});



export const AllStyle = {
  errorTextStyle: {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px",
  },
  popup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    color: "#0F172A",
    borderRadius: "8px",
    minWidth: "365px",
    fontWeight: 400,
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "8px",
  },
  petReference: {
    color: "#0A2239",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: 'Lato',
    cursor: "pointer",
    lineHeight: "19.2px"
  },
  checkBoxStyle: {
    marginRight: "8px",
    width: "20px",
    height: "20px"
  },
  btnStyle: {
    color: "#FFFFFF",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px"
    , marginBottom: "30px",
    borderRadius: "8px",
    height: "56px",
    background: "#00bdab",
    textTransform: "inherit" as "inherit",

  },
  btnStylePopUp: {
    color: "#FFFFFF",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    width: "120px",
    height: "56px",
    background: "#00bdab",
    textTransform: "inherit" as "inherit",

  },
  boldStyle: { color: "00bdab", fontSize: "16px", fontFamily: "Lato", fontWeight: 700 },
  secondBox: {
    flexDirection: "column" as "column",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
  textStyle: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    marginBottom: "4px",
    color: "#0A2239"
  },
  heading: {
    textAlign: "center" as "center",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Lato",
    color: "#0A2239",
    lineHeight: "32px",
    marginBottom: "30px",
    letterSpacing: "-0.12px"
  },
  imageStyle: {
    marginBottom: "10px",
    width: "40px",
    marginTop: "20px",
  },
  BoxStyle: {
    width: "147px",
    height: "154px",
    display: "flex",
    cursor: "pointer" as "pointer",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    textAlign: "center" as "center",
    borderRadius: "15px",
  },


};

// Customizable Area End
